import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";

import * as action from "../../store/actions";
import { SpinnerCenter } from "../common/SpinnerCenter";
import Copy from "../../assets/images/Copy";
import { Timer } from "./Timer";
import {
  FormatNumber,
  GetLogosrc,
  dispatchNotifications,
  translateMessage,
} from "../../helper/constant";
import BackBar from "./BackBar";
import Space from "../common/Space";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";

const BlockchainWalletAddress = () => {
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [network, setNetwork] = useState<string[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<IQuote | null>(null);

  const dispatch = useDispatch();
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  const copyClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatchNotifications({
      message: translateMessage("TEXT COPIED!"),
      color: "blue",
    });
  };

  useEffect(() => {
    const network = modalState.blockchainWallet.network;

    if (!network) {
      let data = {
        currencyId: modalState.blockchainWallet.currencyId,
        apiKey: modalState.widgetData.apikey,
        checkoutId: modalState.checkoutData.checkoutId,
      };
      dispatch(action.getBlockchainWalletAddress(data));
      return;
    }

    const networkOptions = network.split(",");
    if (
      networkOptions &&
      networkOptions.length &&
      networkOptions.length === 1
    ) {
      onNetworkSelect(networkOptions[0]);
      setNetwork(networkOptions);
    } else {
      setNetwork(networkOptions);
    }
  }, []);

  useEffect(() => {
    if (selectedNetwork && selectedCurrency) {
      let data = {
        currencyId: modalState.blockchainWallet.currencyId,
        apiKey: modalState.widgetData.apikey,
        checkoutId: modalState.checkoutData.checkoutId,
        network: selectedNetwork,
      };
      dispatch(action.getBlockchainWalletAddress(data));
    }
  }, [selectedNetwork, selectedCurrency]);

  const timeInMs = (expiry: string): number => {
    const date = new Date(expiry);
    const date2 = new Date();
    return date.getTime() - date2.getTime();
  };

  function onNetworkSelect(value: string) {
    setSelectedNetwork(value);
    let quote =
      modalState.checkoutDetails.quotes.find(
        (item) =>
          item.currencyId === modalState.blockchainWallet.currencyId &&
          item.currencyType === "blockchain" &&
          // item.network?.includes(value) &&
          item.currencyNetwork===value
      ) || null;
    setSelectedCurrency(quote);
  }

  let addressContent;

  let networkSelect = (
    <>
      <div className="select-network">
        <div className="select-network-left">
          {translateMessage("Select Network")}
        </div>

        <div className="select-network-right">
          <select
            className="select-network-dropdown"
            value={selectedNetwork}
            onChange={(x) => x && onNetworkSelect(x.target.value)}
          >
            <option value={""}>{translateMessage("Select Network")}</option>
            {network.map((x) => {
              return (
                <option key={x} value={x}>
                  {x}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </>
  );

  let singleNetwork = (
    <>
      <div className="select-network">
        <div className="select-network-left">{translateMessage("Network")}</div>

        <div className="select-network-right">{selectedNetwork}</div>
      </div>
    </>
  );

  if (network.length > 0) {
    if (selectedNetwork && selectedCurrency) {
      addressContent = (
        <div className="blockchain-screen">
          {network.length === 1 && singleNetwork}
          {network.length > 1 && networkSelect}
          {modalState.blockchainWallet.loading ? (
            <SpinnerCenter />
          ) : (
            <>
              <div className="qr-sec py-2">
                <QRCode value={modalState.blockchainWallet.address! || ""} />
              </div>
              <div
                className="copy-payment cursor"
                onClick={() =>
                  copyClipboard(modalState.blockchainWallet.address!)
                }
              >
                <h4>{modalState.blockchainWallet.address}</h4>
                <div>
                  <Copy />
                </div>
              </div>
              {modalState.blockchainWallet.destinationTag ? (
                <>
                  <div
                    className="copy-payment cursor"
                    onClick={() =>
                      copyClipboard(modalState.blockchainWallet.destinationTag!)
                    }
                  >
                    <h4>
                      Destination Tag:{" "}
                      {modalState.blockchainWallet.destinationTag}
                    </h4>
                    <div>
                      <Copy />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="pay-section">
                <h3>
                  {translateMessage("PAY")}{" "}
                  {FormatNumber(selectedCurrency.total!)}{" "}
                  <img
                    src={GetLogosrc(selectedCurrency.currencyName!)}
                    alt="logo"
                  />
                </h3>
              </div>
              <div className="breakdown-section">
                <div className="breakdown-sec">
                  <h5>{translateMessage("Breakdown")}</h5>
                  {!["completed", "cancelled"].includes(
                    modalState.checkoutDetails.status || "pending",
                  ) &&
                    modalState.checkoutDetails.expiryTime && (
                      <h6>
                        {translateMessage("Expires in")}{" "}
                        <Timer
                          ms={timeInMs(modalState.checkoutDetails.expiryTime!)}
                        />
                      </h6>
                    )}
                </div>
                <div className="detail-sec">
                  <div className="row-li">
                    <p>{translateMessage("Amount")}</p>
                    <p>
                      {FormatNumber(selectedCurrency.amount!)}{" "}
                      {selectedCurrency.currencyName}
                    </p>
                  </div>
                  <div className="row-li">
                    <p>{translateMessage("Fee")}</p>
                    <p>
                      {FormatNumber(selectedCurrency.fee!)}{" "}
                      {selectedCurrency.currencyName}
                    </p>
                  </div>
                  <div className="row-li">
                    <p>{translateMessage("Sub Total")}</p>
                    <p>
                      {FormatNumber(selectedCurrency.subTotal!)}{" "}
                      {selectedCurrency.currencyName}
                    </p>
                  </div>
                  {
                    <div className="row-li">
                      <p className="tooltip-div">
                        {translateMessage("Network Surcharge")}
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="my-tooltip-id">
                              By paying using GlobiancePay wallet via mobile app
                              or netbanking you save on network fees!
                            </Tooltip>
                          }
                          placement="top"
                        >
                          <p>
                            <FaInfoCircle height={10} width={10} />
                          </p>
                        </OverlayTrigger>
                      </p>
                      <p>
                        {FormatNumber(selectedCurrency.networkSurchargeAmount!)}
                      </p>
                    </div>
                  }
                  <div className="row-li no-bd">
                    <p>{translateMessage("Total")}</p>
                    <p>
                      {FormatNumber(selectedCurrency.total!)}{" "}
                      {selectedCurrency.currencyName}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    } else {
      addressContent = <div className="blockchain-screen">{networkSelect}</div>;
    }
  } else if (!modalState.blockchainWallet.network) {
    addressContent = (
      <div className="blockchain-screen">
        {modalState.blockchainWallet.loading ? (
          <SpinnerCenter />
        ) : (
          <>
            <div className="qr-sec py-2">
              <QRCode value={modalState.blockchainWallet.address! || ""} />
            </div>
            <div
              className="copy-payment cursor"
              onClick={() =>
                copyClipboard(modalState.blockchainWallet.address!)
              }
            >
              <h4 className="u-color-main">
                {modalState.blockchainWallet.address}
              </h4>
              <div>
                <Copy />
              </div>
            </div>
            <div className="pay-section">
              <h3>
                {translateMessage("PAY")}{" "}
                {FormatNumber(modalState.blockchainWallet.total!)}{" "}
                <img
                  src={GetLogosrc(modalState.blockchainWallet.currencyName!)}
                  alt="logo"
                />
              </h3>
            </div>
            <div className="breakdown-section">
              <div className="breakdown-sec">
                <h5>{translateMessage("Breakdown")}</h5>
                {!["completed", "cancelled"].includes(
                  modalState.checkoutDetails.status || "pending",
                ) &&
                  modalState.checkoutDetails.expiryTime && (
                    <h6>
                      {translateMessage("Expires in")}{" "}
                      <Timer
                        ms={timeInMs(modalState.checkoutDetails.expiryTime!)}
                      />
                    </h6>
                  )}
              </div>
              <div className="detail-sec">
                <div className="row-li">
                  <p>{translateMessage("Amount")}</p>
                  <p>
                    {FormatNumber(modalState.blockchainWallet.amount!)}{" "}
                    {modalState.blockchainWallet.currencyName}
                  </p>
                </div>
                <div className="row-li">
                  <p>{translateMessage("Fee")}</p>
                  <p>
                    {FormatNumber(modalState.blockchainWallet.fee!)}{" "}
                    {modalState.blockchainWallet.currencyName}
                  </p>
                </div>
                <div className="row-li">
                  <p>{translateMessage("Sub Total")}</p>
                  <p>
                    {FormatNumber(modalState.blockchainWallet.subTotal!)}{" "}
                    {modalState.blockchainWallet.currencyName}
                  </p>
                </div>
                {
                  <div className="row-li">
                    <p className="tooltip-div">
                      {translateMessage("Network Surcharge")}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="my-tooltip-id">
                            By paying using GlobiancePay wallet via mobile app
                            or netbanking you save on network fees!
                          </Tooltip>
                        }
                        placement="top"
                      >
                        <p>
                          <FaInfoCircle height={10} width={10} />
                        </p>
                      </OverlayTrigger>
                    </p>
                    <p>
                      {FormatNumber(
                        modalState.blockchainWallet.networkSurchargeAmount!,
                      )}{" "}
                      {modalState.blockchainWallet.currencyName}
                    </p>
                  </div>
                }
                <div className="row-li no-bd">
                  <p>{translateMessage("Total")}</p>
                  <p>
                    {FormatNumber(modalState.blockchainWallet.total!)}{" "}
                    {modalState.blockchainWallet.currencyName}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  } else {
    addressContent = <div className="blockchain-screen" />;
  }

  return (
    <>
      <BackBar />
      {addressContent}
      <Space height={"10px"} width={0} />
    </>
  );
};

export default BlockchainWalletAddress;
