import React from "react";
import { Spinner } from "react-bootstrap";

export const SpinnerCenter = ({text = null} : {text?: string | null}) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
      <Spinner animation="border" />
      {text && <h5 className="mt-2">{text}</h5>}
    </div>
  );
};
