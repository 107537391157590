import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BackBar from "./BackBar";
import * as action from "../../store/actions/index";
import { dispatchNotifications, translateMessage } from "../../helper/constant";
import { SpinnerCenter } from "../common/SpinnerCenter";
import ErrorImage from "../../assets/images/error-img.png";

function InitiatlizeInstaXchange() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [error, setError] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState<IQuote | null>(null);

  function getCryptoToken(str: string, quote: IQuote) {
    const exist = modalState.checkoutDetails.quotes.filter(
      (item) =>
        quote?.currencyId === item.currencyId && str === item.currencyType
    );
    if (!exist || !exist.length) return null;
    const merged = exist.reduce((acc, cur) => {
      if (!acc.currencyName) acc = cur;
      else {
        if (cur.network && !acc.network?.split(",").includes(cur.network))
          acc.network += `,${cur.network}`;
      }
      return acc;
    }, {});
    return merged;
  }

  function getType() {
    const exist = modalState.checkoutDetails.quotes
      .filter((item) => "USDT" === item.currencyName)
      .find((item) => "blockchain" === item.currencyType);
    if (exist) return exist;
    return null;
  }

  useEffect(()=>{
    const usdtQuote = getType()!;
    const quote: IQuote = getCryptoToken("blockchain", usdtQuote)!;
    dispatch(action.selectBlockChainWalletAddress(quote));

    if(!quote.network) {
      const errorMessage = "PLEASE CONTACT MERCHANT TO ENABLE THIS FEATURE";
      dispatchNotifications({
        message: errorMessage,
        color: "red",
      });
      setError(errorMessage);
    }

  }, [])

  const initializeInstaX = async (address : any) => {
    setLoading(true);

    let body = {
      apiKey: modalState.widgetData.apikey,
      checkoutId: modalState.checkoutData.checkoutId,
      address: address,
      toAmount: selectedCurrency!.total,
      fromCurrency: modalState.checkoutDetails.requestCurrency,
      toCurrency: "USDT",
    };

    dispatch(action.getInstaxWidgetLink(body, () => {
      setLoading(false);
    }, () => {
      setLoading(false);

      const errorMessage = "ERROR FETCHING PAYMENT LINK";
      dispatchNotifications({
        message: errorMessage,
        color: "red",
      });
      setError(errorMessage);
    }));
  
  };

  useEffect(() => {
    if(modalState.blockchainWallet && modalState.blockchainWallet.network) {
      onNetworkSelect("ETH");
    }
  }, [modalState.blockchainWallet]);

  useEffect(() => {
    if (selectedNetwork && selectedCurrency) {
      setLoading(true);
  
      let data = {
        currencyId: modalState.blockchainWallet.currencyId,
        apiKey: modalState.widgetData.apikey,
        checkoutId: modalState.checkoutData.checkoutId,
        network: selectedNetwork,
      };
      dispatch(
        action.getBlockchainWalletAddress(data,
          (address: any) => {
            initializeInstaX(address);
          },
          () => {
            setLoading(false);
          }
        ));
    }
  }, [selectedNetwork, selectedCurrency]);

  function onNetworkSelect(value: string) {
    setSelectedNetwork(value);
    let quote =
      modalState.checkoutDetails.quotes.find(
        (item) =>
          item.currencyId === modalState.blockchainWallet.currencyId &&
          item.currencyType === "blockchain" &&
          item.currencyNetwork === value
      ) || null;
    setSelectedCurrency(quote);
  }

  let addressContent;

  if(modalState.blockchainWallet.loading || modalState.buyCryptoInfo.loading || loading) {
    addressContent = (
      <div className="blockchain-screen">
         <SpinnerCenter />
      </div>
    )
  } else if(error) {
    addressContent = (
      <div className="blockchain-screen">
        <div className="error-screen h-vh-40">
          <img className="mt-5" src={ErrorImage} alt="Error" />
          <h6 className="mt-3 text-center">{error}</h6>
        </div>
      </div>
    )
  } else if(selectedNetwork && selectedCurrency && modalState.buyCryptoInfo.sessionLink) {
    addressContent = (
      <div className="blockchain-screen">
        <div className="instax-wrapper">
          <iframe className="instax-wrapper-iframe" src={modalState.buyCryptoInfo.sessionLink} title="Payment Method"></iframe>
        </div>
      </div>
    )
  }

  return (
    <>
      <BackBar className="py-0" />
      {addressContent}
    </>
  );
}

export default InitiatlizeInstaXchange;
