import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { SpinnerCenter } from "../common/SpinnerCenter";
import MainPageContent from "./MainPageContent";
import QRCodeScreen from "./QRCodeScreen";
import ErrorFallback from "../common/ErrorFallback";
import CancelScreen from "./CancelScreen";
import BlockchainWalletAddress from "./BlockchainWalletAddress";
import PaymentListScreen from "./PaymentListScreen";
import InitializePaybis from "./InitializePaybis";
import ExpiryScreen from "./ExpiryScreen";
import Completed from "./Completed";

import { POLL_INTERVAL } from "../../helper/constant";
import * as action from "../../store/actions";
import { useDispatch } from "react-redux";
import InitiatlizeInstaXchange from "./InitiatlizeInstaXchange";

const MainPage = () => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  useEffect(() => {
    let intRef: NodeJS.Timer = setInterval(() => {
      if (modalState.expired) intRef && clearInterval(intRef);

      if (modalState.checkoutData.checkoutId) {
        let data = {
          apiKey: modalState.widgetData.apikey,
          checkoutId: modalState.checkoutData.checkoutId,
        };
        dispatch(action.getCheckoutDetail(data));
      }
    }, POLL_INTERVAL);

    return () => {
      if (intRef) clearInterval(intRef);
    };
  }, [modalState.checkoutData.checkoutId, dispatch]);

  useEffect(() => {
    const modalWidScreen = document.getElementById("modal-wid-screen");

    if (modalWidScreen) {
      modalWidScreen.scrollTop = 0;
    }
  }, [modalState.widgetScreen]);

  return (
    <>
      {modalState.checkoutData.loading ? (
        <SpinnerCenter text={"Loading..."}/>
      ) : modalState.widgetScreen === "Main" ? (
        <MainPageContent />
      ) : modalState.widgetScreen === "QR" ? (
        <QRCodeScreen />
      ) : modalState.widgetScreen === "Cancel" ? (
        <CancelScreen />
      ) : modalState.widgetScreen === "BlockchainWallet" ? (
        <BlockchainWalletAddress />
      ) : modalState.widgetScreen === "PaymentList" ? (
        <PaymentListScreen />
      ) : modalState.widgetScreen === "Expired" ? (
        <ExpiryScreen />
      ) : modalState.widgetScreen === "Completed" ? (
        <Completed />
      ) : modalState.widgetScreen === "BuyCrypto" ? (
        <InitiatlizeInstaXchange />
      ) : (
        <ErrorFallback />
      )}
    </>
  );
};

export default MainPage;
